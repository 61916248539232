export default {
  SET_APPOINTMENTS(state, appointments) {
    state.appointments = appointments;
  },
  SET_CONSULTATION_INFO(state, consultationInfo) {
    state.consultationInfo = consultationInfo
  },
  SET_APPOINTMENTS_SOURCE(state, appointmentsSource) {
    state.appointmentsSource = appointmentsSource
  },
  SET_CONSULTATION_DATE(state, consultationDate) {
    state.consultationDate = consultationDate;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_CHECK_IN_LOADING(state, checkInLoading) {
    state.checkInLoading = checkInLoading
  },
  SET_DOCTORS(state, doctors) {
    state.doctors = doctors
    state.doctor = doctors[0]
  },
  START_CONSULTATION(state, {payload,appointmentVal}) {
    // const currentItem = state.appointments.find(appointment => appointment.id === payload.id);
    // let index = state.appointments.indexOf(currentItem);
    // let newItem = Object.assign({}, currentItem);
    // newItem.consultationStartedAt = payload.consultationStartedAt;
    // newItem.status = 3;
    // state.appointments.splice(index, 1, newItem);
    if (appointmentVal.tokenType == 1) {
      const currentItem = state.appointments.scheduled.appointments.find(appointment => appointment.roomId === appointmentVal.roomId);
      let index = state.appointments.scheduled.appointments.indexOf(currentItem);
      let newItem = Object.assign({}, currentItem);
      newItem.consultationStartedAt = payload.consultationStartedAt;
      state.appointments.scheduled.appointments.splice(index, 1, newItem);
    }
    else if (appointmentVal.tokenType == 2) {
      const currentItem = state.appointments.walkIn.appointments.find(appointment => appointment.roomId === appointmentVal.roomId);
      let index = state.appointments.walkIn.appointments.indexOf(currentItem);
      let newItem = Object.assign({}, currentItem);
      newItem.consultationStartedAt = payload.consultationStartedAt;
      state.appointments.walkIn.appointments.splice(index, 1, newItem);
    }


  },
  FINISH_CONSULTATION(state, {payload,appointmentVal}) {
    // const currentItem = state.appointments.find(appointment => appointment.id === payload.id);
    // let index = state.appointments.indexOf(currentItem);
    // let newItem = Object.assign({}, currentItem);
    // newItem.consultationCompletedAt = payload.consultationCompletedAt;
    // state.appointments.splice(index, 1, newItem);
    if (appointmentVal.tokenType == 1) {
      const currentItem = state.appointments.scheduled.appointments.find(appointment => appointment.roomId === appointmentVal.roomId);
      let index = state.appointments.scheduled.appointments.indexOf(currentItem);
      let newItem = Object.assign({}, currentItem);
      newItem.consultationCompletedAt = payload.consultationCompletedAt;
      state.appointments.scheduled.appointments.splice(index, 1, newItem);
    }
    else if (appointmentVal.tokenType == 2) {
      const currentItem = state.appointments.walkIn.appointments.find(appointment => appointment.roomId === appointmentVal.roomId);
      let index = state.appointments.walkIn.appointments.indexOf(currentItem);
      let newItem = Object.assign({}, currentItem);
      newItem.consultationCompletedAt = payload.consultationCompletedAt;
      state.appointments.walkIn.appointments.splice(index, 1, newItem);
    }
  },

  CALL_CONSULTATION(state, {payload,appointmentVal}) {
    // const currentItem = state.appointments.find(appointment => appointment.id === payload.id);
    // let index = state.appointments.indexOf(currentItem);
    // let newItem = Object.assign({}, currentItem);
    // newItem.calledAt = payload.calledAt;
    // newItem.calling = true;
    // state.appointments.splice(index, 1, newItem);
    if (appointmentVal.tokenType == 1) {
      const currentItem = state.appointments.scheduled.appointments.find(appointment => appointment.roomId === appointmentVal.roomId);
      let index = state.appointments.scheduled.appointments.indexOf(currentItem);
      let newItem = Object.assign({}, currentItem);
      newItem.calledAt = payload.calledAt;
      state.appointments.scheduled.appointments.splice(index, 1, newItem);
    }
    else if (appointmentVal.tokenType == 2) {
      const currentItem = state.appointments.walkIn.appointments.find(appointment => appointment.roomId === appointmentVal.roomId);
      let index = state.appointments.walkIn.appointments.indexOf(currentItem);
      let newItem = Object.assign({}, currentItem);
      newItem.calledAt = payload.calledAt;
      state.appointments.walkIn.appointments.splice(index, 1, newItem);
    }
  },
  SET_CALLING_APPOINTMENT(state, callingAppointment) {
    state.callingAppointment = callingAppointment
  },
  SET_REPORTS(state, reports) {
    state.reports = reports
  },
  SET_REPORTS_LOADING(state, reportsLoading) {
    state.reportsLoading = reportsLoading
  },
  SET_LAB_PATIENT(state, labPatient) {
    state.labPatient = labPatient
  },
  SET_REPORT_DIALOG(state, reportDialog) {
    state.reportDialog = reportDialog
  },
  SET_PRESCRIPTIONS_DIALOG(state, prescriptionsDialog) {
    state.prescriptionsDialog = prescriptionsDialog
  },
  SET_WRITE_PRESCRIPTIONS_DIALOG(state, writePrescriptionsDialog) {
    state.writePrescriptionsDialog = writePrescriptionsDialog
  },
  SET_CONSULTATION_HISTORY_DIALOG(state, consultationHistoryDialog) {
    state.consultationHistoryDialog = consultationHistoryDialog
  },
  SET_CALL_MASK_DIALOG(state, callMaskDialog) {
    state.callMaskDialog = callMaskDialog
  },
  SET_UPLOAD_DIALOG(state, uploadDialog) {
    state.uploadDialog = uploadDialog
  },
  SET_UPLOAD_PATIENT(state, uploadPatient) {
    state.uploadPatient = uploadPatient
  },
  SET_UPLOAD_LOADING(state, uploadLoading) {
    state.uploadLoading = uploadLoading
  },
  SET_NOTE_DIALOG(state, noteDialog) {
    state.noteDialog = noteDialog
  },
  SET_NOTES(state, notes) {
    state.notes = notes
  },
  SET_NOTES_PATIENT(state, notesPatientId) {
    state.notesPatientId = notesPatientId
  },
  ADD_NOTE(state, note) {
    state.notes.unshift(note)
  },
  EDIT_NOTE(state, payload) {
    const currentItem = state.notes.find(note => note.id === payload.id);
    let index = state.notes.indexOf(currentItem);
    let newItem = Object.assign({}, currentItem);
    newItem.name = payload.name;
    state.notes.splice(index, 1, newItem);
  },
  SET_FILES_DIALOG(state, filesDialog) {
    state.filesDialog = filesDialog
  },
  SET_PATIENT_FILES(state, patientFiles) {
    state.patientFiles = patientFiles
  },
  SET_PATIENT_DETAILS(state, patientDetails) {
    state.patientDetails = patientDetails
  },
  SET_DOCTOR_STATUS(state, doctorStatus) {
    state.doctorStatus = doctorStatus
  },
  SET_SCORE_CARD(state, scoreCard) {
    state.scoreCard = scoreCard
  },
  SET_DELAY(state, delay) {
    state.delayDetails = delay
  },
  SET_COVID_DIALOG(state, covidDialog) {
    state.covidDialog = covidDialog
  },
  PRESCRIPTION_ALERT_CLOSED(state, payload) {
    const currentItem = state.appointments.scheduled.appointments.find(appointment => appointment.roomId === payload.roomId);
    let index = state.appointments.scheduled.appointments.indexOf(currentItem);
    let newItem = Object.assign({}, currentItem);
    newItem.prescriptionAlertClosed = "closed";
    state.appointments.scheduled.appointments.splice(index, 1, newItem);
  },
  SET_BLOB_URL(state, blobUrl) {
    state.blobUrl = blobUrl;
  },
  SET_PATIENT_FILES_LOADING(state, dat) {
    state.patientFilesLoading = dat
  },
  SET_DOCTOR_OS(state, os) {
    state.doctorOs = os
  },
  SET_ALLOWED_PATIENT_FILES_COUNT(state, allowedPatientFileCount) {
    state.allowedPatientFileCount = allowedPatientFileCount
  },
  SET_PATIENT_FILES_COUNT(state, patientFileCount) {
    state.patientFileCount = patientFileCount
  },
  ADD_NEW_PATIENT_FILE(state, patientFiles) {
    state.patientFileCount = state.patientFileCount + 1
    state.patientFiles.push(patientFiles)
  },
  SET_NOTIFICATION_DIALOG(state, val) {
    state.notificationDialog = val
  },
  SET_PATIENT_NOTIFICATION(state, patientNotification) {
    state.patientNotification = patientNotification
  },
  SET_PATIENT_NOTIFICATION_LOADING(state, patientNotificationLoading) {
    state.patientNotificationLoading = patientNotificationLoading
  },
  SET_PATIENT_STATUS(state, patientStatus) {

    state.patientStatus = patientStatus
  },
  SET_PATIENT_LOG(state, patientLogs) {

    state.patientLogs = patientLogs
  },
  SET_PATIENT_LOG_LOADING(state, patientLogLoading) {

    state.patientLogLoading = patientLogLoading
  },
  SET_SELECTED_APPOITMENT(state, selectedAppointment) {
    state.selectedAppointment = selectedAppointment
  },
  UPDATE_SELECTED_APPOITMENT_START(state, consultationStartedAt) {
    state.selectedAppointment.consultationStartedAt = consultationStartedAt
  },
  UPDATE_SELECTED_APPOITMENT_FINISH(state, consultationCompletedAt) {
    state.selectedAppointment.consultationCompletedAt = consultationCompletedAt
  },
  SET_SELECTED_SORT_OPTION(state, selectedSortOption) {
    state.selectedSortOption = selectedSortOption
  },
  SET_SELECTED_SORT_OPTION_MOBILE(state, selectedSortOption) {
    state.selectedSortOptionMobile = selectedSortOption
  },
  SET_PATIENT_DETAILS_DIALOG(state, patientDetailsDialog) {
    state.patientDetailsDialog = patientDetailsDialog
  },
  SET_TAB_VALUE(state, tabValue) {
    state.tabValue = tabValue
  },
  SET_CONSULTATION_LOADING(state, startConsultationLoading) {
    state.startConsultationLoading = startConsultationLoading
  },
  SET_CONSULTATION_TYPE(state, consultationType) {
    state.consultationType = consultationType
  },
  SET_ERR_ON_FETCHING(state, errOnFetching) {
     state.errOnFetching = errOnFetching
  },
  SET_BOTTOM_SHEET(state, bottomSheet) {
    state.bottomSheet = bottomSheet
  },


}
