<template>
  <v-app>
    <v-content class="white">
      <nuxt />
    </v-content>
  </v-app>
</template>

<script>
export default {}
</script>
