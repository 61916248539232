import moment from 'moment'
import axios from 'axios'


export default class Utils {
  static getCurrentDate() {
    const today = new Date();
    return this.formatDate(today);
  }

  static getCurrentDateInUTC() {
    return moment.utc().format('YYYY-MM-DD')
  }
  static getCurrentDateInLong() {
    return moment.utc().format('MMM D, YYYY')
  }

  static getCurrentTime() {
    return moment().format('HH:mm:ss')
  }

  static getCurrentTime12() {
    var date = new Date();
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return hours + ":" + minutes + ":" + seconds + " " + am_pm;
}

  static formatDate(date) {
    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDate()}`;
    const year = date.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  static getDate() {
    return moment().format('YYYY-MM-DD')
  }

  static getCancelTokenSource() {
    return axios.CancelToken.source();
  }

  static cancelToken(source) {
    try {
      if (source) {
        source.cancel("Operation canceled by the user.");
      }
    } catch (error) { }
  }

  static dateFilter(value) {
    if (value) {
      return moment(value.trim(), 'YYYY-MM-DD').format('ddd, MMM DD')
    } else {
      return '';
    }
  };

  static dateFilter2(value) {
    if (value) {
      return  moment(value.trim(), 'YYYY-MM-DD').format('DD MMM YYYY')
    } else {
      return '';
    }
  };
  static formatWithoutAMPM(val) {
    let v = val.split('T')
    return v[1].split('.')[0]
  }
  static formatWithAMPM(val) {
    return moment(val).format('hh:mm:ss a')

  }
  static formatWithAMPMWithoutSeconds(val) {
    return moment(val).format('hh:mm a')

  }
  static getTimeDifference(val1, val2) {
    try {
      let start = this.formatWithoutAMPM(val1).split(":")
      let end = this.formatWithoutAMPM(val2).split(":")
      let startSec = parseInt(start[0]) * 3600 + parseInt(start[1]) * 60 + parseInt(start[2])
      let endSec = parseInt(end[0]) * 3600 + parseInt(end[1]) * 60 + parseInt(end[2])

      var sec_num = -1 * (startSec - endSec)
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      // if (hours < 10) { hours = "0" + hours; }
      // if (minutes < 10) { minutes = "0" + minutes; }
      // if (seconds < 10) { seconds = "0" + seconds; }

      return (hours === 0 ? '' : hours + 'h') + (minutes === 0 ? '' : minutes + 'm') + (seconds === 0 ? '' : seconds + 's')

    }
    catch (err) {
      console.log(err)
    }
  }

  static getDateFromTimestamp(val) {
    if (val) {
      return val.split('T')[0]
    }
    return ''
  }

  static changeDateFormat(val) {
    if (val) {
      return moment(val.trim(), 'YYYY-MM-DD').format('DD-MM-YYYY')
    } else {
      return '';
    }
  }
  static timeFilter(value) {
    if (value) {
      return moment(value.trim(), 'HH:mm:ss').format('hh:mm a')
    } else {
      return '';
    }
  };
}
