import Hashids from 'hashids'
const hashids = new Hashids('connect and consult', 10);
export default {
  unreadMessages(state) {
    return state.messages.filter(
      message => !message.read && message.type === 'Patient'
    ).length
  },
  roomId(state) {
    return state.info ? state.info.roomId : null
  },
  user(state) {
    return state.info.doctorName
  }
}