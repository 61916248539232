<template>
  <v-app>
    <v-content style="background: #f8fbff!important;">>
      <nuxt />
    </v-content>
  </v-app>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {
    this.$vuetify.icons.iconfont = 'md'
  }
}
</script>
