export default {
  doctorsOnDisplay(state) {
    let list = [];
    for (let selectedDoctor of state.selectedDoctors) {
      for (let checkedInDoctorId of state.checkedInDoctorIds) {
        if (checkedInDoctorId === selectedDoctor.doctorId) {
          list.push(selectedDoctor);
        }
      }
    }
    return list;
  },
  locationDetail(state){
    return state.locationDetail
  }
}
