export default {
  scheduled(state) {
    if (state.appointments) {
      const data = state.appointments.scheduled ? state.appointments.scheduled.appointments : []
      if (state.selectedSortOption.id === 1) {
        return data;
      }
      else if (state.selectedSortOption.id === 2) {
        return data.filter(appointment => appointment.consultationType === 2)
      }
      else if (state.selectedSortOption.id === 3) {
        return data.filter(appointment => appointment.consultationType === 1)
      }
      else {
        return data;
      }

    }
    else return []
  },
  walkInAppoinemts(state) {
    if (state.appointments) {
      const data = state.appointments.walkIn ? state.appointments.walkIn.appointments : []
      if (state.selectedSortOption.id === 1) {
        return data;
      }
      else if (state.selectedSortOption.id === 2) {
        return data.filter(appointment => appointment.consultationType === 2)
      }
      else if (state.selectedSortOption.id === 3) {
        return data.filter(appointment => appointment.consultationType === 1)
      }
      else {
        return data;
      }

    }
    else return []
  },
  scheduledCompleted(state) {
    if (state.appointments) {
      const data = state.appointments.scheduled ? state.appointments.scheduled.appointments : []
      if (state.selectedSortOption.id === 1) {
        return state.appointments.scheduled ? state.appointments.scheduled.completed : 0
      }
      else if (state.selectedSortOption.id === 2) {
        return data.filter(appointment => appointment.consultationType === 2 && appointment.status === 3).length
      }
      else if (state.selectedSortOption.id === 3) {
        return data.filter(appointment => appointment.consultationType === 1 && appointment.status === 3).length
      }
      else {
        return state.appointments.scheduled ? state.appointments.scheduled.completed:0
      }
    }
    else return 0
  },
  walkInCompleted(state) {
    if (state.appointments) {
      const data = state.appointments.walkIn ? state.appointments.walkIn.appointments : []
      if (state.selectedSortOption.id === 1) {
        
    return state.appointments.walkIn ? state.appointments.walkIn.completed : 0
      }
      else if (state.selectedSortOption.id === 2) {
        return data.filter(appointment => appointment.consultationType === 2 && appointment.status === 3).length
      }
      else if (state.selectedSortOption.id === 3) {
        return data.filter(appointment => appointment.consultationType === 1 && appointment.status === 3).length
      }
      else { 
        return state.appointments.WalkIn ? state.appointments.WalkIn.Completed : 0
      }

  }
  else return 0

  },
  scheduledCheckIns(state) {
    if (state.appointments) {
      const data = state.appointments.scheduled ? state.appointments.scheduled.appointments : []
      if (state.selectedSortOption.id === 1) {
        return state.appointments.scheduled ? state.appointments.scheduled.checkIns : 0
      }
      else if (state.selectedSortOption.id === 2) {
        return data.filter(appointment => appointment.consultationType === 2 && appointment.status === 2).length
      }
      else if (state.selectedSortOption.id === 3) {
        return data.filter(appointment => appointment.consultationType === 1 && appointment.status === 2).length
      }
      else {
        return state.appointments.Scheduled ? state.appointments.Scheduled.CheckIns:0
      }
    }
    else return 0
  },
  walkInCheckIns(state) {
    if (state.appointments) {
        const data = state.appointments.walkIn ? state.appointments.walkIn.appointments : []
        if (state.selectedSortOption.id === 1) {
          
      return state.appointments.walkIn ? state.appointments.walkIn.checkIns : 0
        }
        else if (state.selectedSortOption.id === 2) {
          return data.filter(appointment => appointment.consultationType === 2 && appointment.status === 2).length
        }
        else if (state.selectedSortOption.id === 3) {
          return data.filter(appointment => appointment.consultationType === 1 && appointment.status === 2).length
        }
        else { 
          return state.appointments.walkIn ? state.appointments.walkIn.checkIns : 0
        }

    }
    else return 0
  },
  waitingPeriod(state) {
    if (state.appointments) {
      return state.appointments.waitingPeriod ? state.appointments.waitingPeriod : {
        "scheduled": "-",
        "walkIn": "-",
        "tele": "-"
      }

    }
    else return {
      "scheduled": "-",
      "walkIn": "-",
      "tele": "-"
    }
  },
  consultationTAT(state) {
    if (state.appointments) {
      return state.appointments.consultationTAT ? state.appointments.consultationTAT : {
        "scheduled": "-",
        "tele": "-",
        "walkIn": "-"
      }
    }
    else return {
      "scheduled": "-",
      "tele": "-",
      "walkIn": "-"
    }
  },
  walkIns(state) {
    return state.appointments.filter(appointment => appointment.tokenType === 2).map((appointment, index) => ({
      no: index + 1,
      ...appointment
    })).sort((a, b) => (parseInt(a.hospitalToken) > parseInt(b.hospitalToken)) ? 1 : -1)
  },
  scheduledSummary(state, getters) {
    if (getters.scheduled.length === 0) return 'Scheduled'
    let checkedInCount = 0;
    let consultationCount = 0;
    for (let appointment of getters.scheduled) {
      if (appointment.status === 2) {
        checkedInCount++
      } else if (appointment.status === 3) {
        // checkedInCount++
        consultationCount++
      }
    }
    let patientText = '';
    let checkedInText = '';
    let consultationText = '';

    patientText = getters.scheduled.length + (getters.scheduled.length === 1 ? ' patient' : ' patients') + (checkedInCount === 0 & consultationCount === 0 ? ')' : ' • ');
    if (checkedInCount !== 0) {
      checkedInText = checkedInCount + (checkedInCount === 1 ? ' check-in' : ' check-ins') + (consultationCount === 0 ? ')' : ' • ');
    }
    if (consultationCount !== 0) {
      consultationText = consultationCount + (consultationCount === 1 ? ' consultation)' : ' consultations)');
    }

    return 'Scheduled (' + patientText + checkedInText + consultationText
  },

  walkInSummary(state, getters) {
    if (getters.walkIns.length === 0) return 'Walk-in'
    let checkedInCount = 0;
    let consultationCount = 0;
    for (let appointment of getters.walkIns) {
      if (appointment.status === 2) {
        checkedInCount++
      } else if (appointment.status === 3) {
        checkedInCount++
        consultationCount++
      }
    }
    let patientText = '';
    let checkedInText = '';
    let consultationText = '';

    patientText = getters.walkIns.length + (getters.walkIns.length === 1 ? ' patient' : ' patients') + (checkedInCount === 0 & consultationCount === 0 ? ')' : ' • ');
    if (checkedInCount !== 0) {
      checkedInText = checkedInCount + (checkedInCount === 1 ? ' check-in' : ' check-ins') + (consultationCount === 0 ? ')' : ' • ');
    }
    if (consultationCount !== 0) {
      consultationText = consultationCount + (consultationCount === 1 ? ' consultation)' : ' consultations)');
    }

    return 'Walk-in (' + patientText + checkedInText + consultationText
  },

  scheduledPhysicalAppointments(state) {
    return state.appointments.filter(appointment => appointment.tokenType === 1).map((appointment, index) => ({
      no: index + 1,
      ...appointment
    }))
  },
  unreadFcmNotification(state) {
    let data = state.patientNotification.filter(
      (item) => item.read === false
    )
    return data.length;
  },
  getCallingAppointment(state) {
    return state.callingAppointment;
  }
}
