export default {
  SET_INFO(state, info) {
    state.info = info;
  },
  SET_DRAWER(state, drawer) {
    state.drawer = drawer
  },
  SET_DRAWER_TAB(state, drawerTab) {
    state.drawerTab = drawerTab
  },
  SET_MESSAGES(state, messages) {
    state.messages = messages
  },
  ADD_MESSAGE(state, message) {
    state.messages.push(message)
  },
  MARK_AS_READ(state) {
    state.messages.map(message => message.read = true)
  }
}