<template>
  <v-app>
    <v-content class="grey lighten-4">
      <nuxt />
    </v-content>
    <confirmation-dialog
      :showDialog="notificationRequestDialog"
      source="notification-permission"
      title="Enable Notifications"
      bodyText="You will now see a popup that asks you for permission to show notifications. To receive real-time notifications on the requests, it is highly recommended that you click “Allow” in the popup."
      confirmationButtonText="OK"
      confirmationButtonColor="#2753BB"
      :showCloseButton="false"
      @user-confirmed="requestPermission"
    />
  </v-app>
</template>

<script>
import confirmationDialog from '~/components/confirmation-dialog.vue'
export default {
  components: { confirmationDialog },
  data() {
    return { notificationRequestDialog: false }
  },
  async mounted() {
    this.$vuetify.icons.iconfont = 'md'
    this.startPermissionListener()
    this.requestPermission()
    this.startOnMessageListener()
  },
  methods: {
    openAllowNotificationDialog() {
      this.notificationRequestDialog = true
    },
    requestPermission() {
      this.notificationRequestDialog = false
      if (window.isSecureContext && Notification) {
        Notification.requestPermission()
      }
    },
    startPermissionListener() {
      if ('permissions' in navigator) {
        navigator.permissions
          .query({ name: 'notifications' })
          .then(function (notificationPerm) {
            notificationPerm.onchange = async function () {
              console.log(notificationPerm.state)
            }
          })
      }
    },
    startOnMessageListener() {
      if (Notification && this.$fire && this.$fire.messaging) {
        this.$fire.messaging.onMessage((data) => {
          console.info('DATA', data)
          if (Notification.permission !== 'granted') {
            this.openAllowNotificationDialog()
          } else {
            navigator.serviceWorker
              .getRegistration('/firebase-cloud-messaging-push-scope')
              .then((registration) => {
                registration.showNotification(data.data.title, {
                  body: data.data.message,
                })
              })
          }
        })
      }
    },
    startTokenRefreshListener() {
      this.$fire.messaging.onTokenRefresh(async () => {
        try {
          const refreshedToken = await this.$fire.messaging.getToken()
          console.log(refreshedToken)
        } catch (e) {
          console.error('Unable to retrieve refreshed token ', e)
        }
      })
    },
  },
}
</script>
