export default {
  scheduled(state) {
   //return state.appointments
    const firstIndex = (roomId) => {
      for (let i = 0; i < state.appointments.length; i++) {
        if (state.appointments[i].roomId === roomId) {
          return i;
        }
      }
      return -1;
    }
    return state.appointments.filter((appointment, index) => appointment.tokenType === 1 && (appointment.status === 2 || appointment.status === 3) && (firstIndex(appointment.roomId) >= index)).map((appointment, index) => ({
      no: index + 1,
      ...appointment
    }))
  },
  walkIns(state) {
    return state.appointments.filter(appointment => appointment.tokenType === 2).map((appointment, index) => ({
      no: index + 1,
      ...appointment
    })).sort((a, b) => (parseInt(a.hospitalToken) > parseInt(b.hospitalToken)) ? 1 : -1)
  },

  scheduledSummary(state, getters) {
    if (getters.scheduled.length === 0) return 'Scheduled'
    let checkedInCount = 0;
    let consultationCount = 0;
    for (let appointment of getters.scheduled) {
      if (appointment.status === 2) {
        checkedInCount++
      } else if (appointment.status === 3) {
        // checkedInCount++
        consultationCount++
      }
    }
    let patientText = '';
    let checkedInText = '';
    let consultationText = '';

    patientText = getters.scheduled.length + (getters.scheduled.length === 1 ? ' patient' : ' patients') + (checkedInCount === 0 & consultationCount === 0 ? ')' : ' • ');
    if (checkedInCount !== 0) {
      checkedInText = checkedInCount + (checkedInCount === 1 ? ' check-in' : ' check-ins') + (consultationCount === 0 ? ')' : ' • ');
    }
    if (consultationCount !== 0) {
      consultationText = consultationCount + (consultationCount === 1 ? ' consultation)' : ' consultations)');
    }

    return 'Scheduled (' + patientText + checkedInText + consultationText
  },

  walkInSummary(state, getters) {
    if (getters.walkIns.length === 0) return 'Walk-in'
    let checkedInCount = 0;
    let consultationCount = 0;
    for (let appointment of getters.walkIns) {
      if (appointment.status === 2) {
        checkedInCount++
      } else if (appointment.status === 3) {
        checkedInCount++
        consultationCount++
      }
    }
    let patientText = '';
    let checkedInText = '';
    let consultationText = '';

    patientText = getters.walkIns.length + (getters.walkIns.length === 1 ? ' patient' : ' patients') + (checkedInCount === 0 & consultationCount === 0 ? ')' : ' • ');
    if (checkedInCount !== 0) {
      checkedInText = checkedInCount + (checkedInCount === 1 ? ' check-in' : ' check-ins') + (consultationCount === 0 ? ')' : ' • ');
    }
    if (consultationCount !== 0) {
      consultationText = consultationCount + (consultationCount === 1 ? ' consultation)' : ' consultations)');
    }

    return 'Walk-in (' + patientText + checkedInText + consultationText
  },
  unreadFcmNotification(state){
    let data = state.patientNotification.filter(
      (item) => item.read ===false
    )
    return data.length;
  },
  scheduledPhysicalAppointments(state) {
    return state.appointments.filter(appointment => appointment.tokenType === 1).map((appointment, index) => ({
      no: index + 1,
      ...appointment
    }))
  }
}
