export default {
  SET_SELECTED_DOCTORS(state, selectedDoctors) {
    state.selectedDoctors = selectedDoctors;
  },
  SET_DOCTORS(state, doctors) {
    state.doctors = doctors;
  },
  SET_CHECKED_IN_DOCTOR_IDS(state, checkedInDoctorIds) {
    state.checkedInDoctorIds = checkedInDoctorIds
  },

  CHANGE_TOKEN(state, payload) {
    const currentItem = state.selectedDoctors.find(doctor => doctor.doctorId === payload.doctorId);
    let index = state.selectedDoctors.indexOf(currentItem);
    let newItem = Object.assign({}, currentItem);
    newItem.token = payload.token;
    state.selectedDoctors.splice(index, 1, newItem);
  },
  SET_TICKER_MESSAGE(state, tickerMessage) {
    state.tickerMessage = tickerMessage
  },
  SET_LOCATION_DETAIL(state, locationDetail){
    state.locationDetail = locationDetail
  }
}
