import Utils from '../../utils/utils'
import moment from 'moment'
import Hashids from 'hashids'
export default {
  fetchAppointments({ commit, state, rootState }) {
    if (state.appointmentsSource) {
      state.appointmentsSource.cancel('Next request triggered')
    }
    commit('SET_APPOINTMENTS_SOURCE', this.$axios.CancelToken.source())
    commit('SET_LOADING', true)
    const doctorId = rootState.auth.user.doctorId
    const fromDate = state.consultationDate
    const toDate = state.consultationDate
    const locationId = this.$auth.user.locationId
      return this.$axios
        .get(
          'doctors/' +
        'appointmentsV1?locationId=' +
          locationId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
          {
            cancelToken: state.appointmentsSource.token
          }
        )
        .then(response => {
          commit('SET_LOADING', false)
          commit(
            'SET_APPOINTMENTS',
            response.data.filter(appointment => appointment.status !== 4)
          )
        })
        .catch(error => {
          commit('SET_LOADING', false)
        })
  },

  addDelay({ state, rootState }, obj) {
    const doctorId = rootState.auth.user.doctorId
    const fromDate = state.consultationDate
    return this.$axios.get(
      'doctors/delay?date=' +
      fromDate +
      '&doctorId=' +
      doctorId +
      '&minutes=' +
      obj.minutes +
      '&mode=' + obj.mode
    )
  },

  fetchReports({ commit }, reportIds) {
    commit('SET_REPORTS_LOADING', true)
    return this.$axios
      .get('doctors/reports?reportIds=' + reportIds.join(','))
      .then(response => {
        commit('SET_REPORTS', response.data)
        commit('SET_REPORTS_LOADING', false)
      })
      .catch(error => {
        commit('SET_REPORTS_LOADING', false)
      })
  },

  callOutToken({ state, rootState, commit }, appointment) {
    if (!appointment.calledAt) {
      this.$axios
        .put('doctors/updateCalledAt?bookingId=' + appointment.id)
        .then(data => {
          commit('CALL_CONSULTATION', data.data)
        })
    }

    this.$axios.get(
      'doctors/' +
      rootState.auth.user.doctorId +
      '/token-generation?token=' +
      (appointment.tokenType === 1
        ? moment(appointment.consultationTime, 'HH:mm:ss').format('h:mm a')
        : 'W' + appointment.hospitalToken)
    )
  },

  getLastCheckedInTime({ commit }) {
    this.$axios
      .get(
        'doctors/check-in-time?doctorId=' +
        this.$auth.user.doctorId +
        '&checkDate=' +
        Utils.getCurrentDate()
      )
      .then(data => {
        commit('setCheckInStatus', data.data)
      })
      .catch(e => { })
  },

  checkIn({ commit }) {
    commit('SET_CHECK_IN_LOADING', true)
    let params = {
      doctorId: this.$auth.user.doctorId,
      typeId: 1,
      locationId: this.$auth.user.locationId
    }
    let vm = this
    this.$axios
      .post('doctors/check-in-check-out', params)
      .then(async data => {
        vm.$axios('doctors/check-in').then(data => { })
        vm.$auth.fetchUser()
        commit('SET_CHECK_IN_LOADING', false)
      })
      .catch(e => {
        commit('SET_CHECK_IN_LOADING', false)
        // commit('setCheckInLoading', false);
      })
  },

  checkOut({ commit }) {
    commit('SET_CHECK_IN_LOADING', true)
    let params = {
      doctorId: this.$auth.user.doctorId,
      typeId: 2,
      locationId: this.$auth.user.locationId
    }
    let vm = this
    this.$axios
      .post('doctors/check-in-check-out', params)
      .then(async data => {
        await vm.$axios('doctors/check-out').then(data => { })
        vm.$auth.fetchUser()
        commit('SET_CHECK_IN_LOADING', false)
      })
      .catch(e => {
        commit('SET_CHECK_IN_LOADING', true)
      })
  },
  // this.info.doctorBrowser = `${platform.name} ${platform.version}`
  // this.info.doctorDevice = `${platform.manufacturer ? platform.manufacturer : ''
  //   } ${platform.product ? platform.product : ''}`
  // this.info.doctorOS = `${os.family} ${os.version}`
  // this.info.patientChannel = channel
  // this.info.patientBrowser = browser
  // this.info.patientDevice = device
  // this.info.patientOS = os
  startConsultation({ state, commit, dispatch }, appointment) {
    let data = {
      //bookingId: appointment.id,
      roomId: appointment.roomId,
      doctorBrowser: appointment.doctorBrowser,
      doctorDevice: appointment.doctorDevice,
      doctorChannel: appointment.doctorChannel,
      doctorOS: appointment.doctorOS,
      patientChannel: appointment.patientChannel,
      patientBrowser: appointment.patientBrowser,
      patientDevice: appointment.patientDevice,
      patientOS: appointment.patientOS
    }
    //   bookingId:appointment.id,
    //           doctorBrowser: appointment.doctorBrowser,
    //     doctorDevice:appointment.doctorDevice,
    //       doctorChannel:appointment.doctorChannel,
    //     doctorOS: appointment.doctorOS,
    //     patientChannel:appointment.patientChannel,
    //     patientBrowser:appointment.patientBrowser,
    //     patientDevice:appointment.patientDevice,
    //     patientOS :appointment.patientOS,

    // }
    // return this.$axios.post(`doctors/startConsultation?bookingId=${appointment.id}&channel=${appointment.patientChannel}&docChannel=${appointment.doctorChannel}`)

    return this.$axios.post(`doctors/starting-consultation`, data)
  },

  finishConsultation({ state, commit, dispatch }, consultationId) {
    return this.$axios.put(
      'doctors/finishConsultation?consultationId=' + consultationId
    )
  },
  completeConsultation(context, booking) {
    console.log(booking)
    return this.$axios.post('doctors/complete-consultation', booking)
  },
  uploadPrescription({ commit, state }, param) {
    commit('SET_UPLOAD_LOADING', true)
    const details = state.uploadPatient
    console.log('action details', details)
    let params = {
      roomId: details.roomId,
      bookingId: details.id,
      document: param.document,
      documentName: param.name,
      documentType: param.type,
      // mobile: details.contactNumber,
      // email: details.email,
      // name: details.name,
      // consultationDate: details.consultationDate,
      // doctorName: details.doctorName
    }
    return this.$axios
      .post('prescriptions/upload', params)
      .then(data => {
        commit('SET_UPLOAD_LOADING', false)
        return data.data
      })
      .catch(e => {
        commit('SET_UPLOAD_LOADING', false)
      })
  },
  fetchDoctorNotes({ commit, rootState }, patientId) {
    return this.$axios
      .get('doctors/viewNotesV1?patientId=' + patientId)
      .then(data => {
        commit(
          'SET_NOTES',
          data.data
          // .sort((a, b) =>
          //   a.name.length > b.name.length
          //     ? 1
          //     : b.name.length > a.name.length
          //       ? -1
          //       : 0
          // )
        )
      })
  },
  getMessages(context, bookingId) {
    //return this.$axios.get('room/messages?bookingId=' + bookingId)
    return this.$axios.get('room/messages?roomId=' + bookingId)
  },
  readMessages(context, { bookingId, type }) {
    // return this.$axios.put(
    //   'room/read-messages?bookingId=' + bookingId + '&type=' + type
    // )
    return this.$axios.put(
      'room/read-messages?roomId=' + bookingId + '&type=' + type
    )
  },
  addNotes({ commit }, obj) {
    let params = {
      patientId: obj.patientId,
      name: obj.note
    }
    return this.$axios.post('doctors/addNotesV1', params)
    // .then(data => {
    //   commit('ADD_NOTE', data.data)
    // })
    // .catch(e => {

    // });
  },
  updateNote({ commit }, obj) {
    return this.$axios.put('doctors/editNotes', obj)
    // .then(data => {
    //   commit('EDIT_NOTE', data.data)
    // })
    // .catch(e => {
    // });
  },
  fetchPatientFiles({ commit, rootState }, bookingId) {
    commit('SET_PATIENT_FILES', [])
    commit('SET_PATIENT_FILES_LOADING', true)
    return this.$axios
      .get('doctors/viewPatientFilesV1?bookingId=' + bookingId)
      .then(data => {
        commit('SET_PATIENT_FILES', data.data)
        commit('SET_PATIENT_FILES_LOADING', false)
      })
  },
  downloadFile({ commit, rootState }, file) {
    const hashids = new Hashids('connect and consult', 10)
    const hashedId = hashids.encode(file.id);
    return this.$axios
      .get('doctors/getPatientFileV1?id=' + hashedId, {
        responseType: 'blob'
      })
      .then(response => {
        const blob = new Blob([response.data], { type: file.documentType })
        var link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file.documentName
        link.click()
      })
  },
  uploadFile({ commit, state }, param) {
    let params = {
      bookingId: param.bookingId,
      documentName: param.name,
      documentType: param.type,
      document: param.document
    }
    return this.$axios
      .post('doctors/uploadFileV1', params)
      .then(data => {

        commit('ADD_NEW_PATIENT_FILE', {
          documentName: param.name,
          documentType: param.type,
        })

        return data.data
      })
      .catch(e => { })
  },
  viewFile({ commit, rootState }, file) {
    const hashids = new Hashids('connect and consult', 10);
    const hashedId = hashids.encode(file.id);
    return this.$axios
      .get('doctors/getPatientFileV1?id=' + hashedId, {
        responseType: 'blob'
      })
      .then(response => {
        const blob = new Blob([response.data], { type: file.documentType })
        let blobURL = URL.createObjectURL(blob)
        commit('SET_BLOB_URL', blobURL)

        window.open(blobURL)
      })
  },

  viewPrescriptionFile({ commit, rootState }, file) {
    console.log(file)
    return this.$axios
      .get('prescriptions/file?id=' + file.id, {
        responseType: 'blob'
      })
      .then(response => {
        const blob = new Blob([response.data], { type: file.type })
        let blobURL = URL.createObjectURL(blob)
        window.open(blobURL)
      })
  },
  createPrescription(context, data) {
    return this.$axios.post('prescriptions', data)
  },
  sendNotificationEmail(context, data) {
    return this.$axios.post('room/notification-email', data)
  },
  getPrescriptions(context, { bookingId, patientId, doctorId }) {
    const hashids = new Hashids('connect and consult', 10)
    const hashedPatientId = hashids.encode(patientId);
    return this.$axios.get(
      `prescriptions/fetch?patientId=${hashedPatientId}`
    )
  },
  getPatientDetails({ context, commit }, roomId) {
    return this.$axios
      .get('room/patient-details?roomId=' + roomId)
      .then(data => {
        commit('SET_PATIENT_DETAILS', data.data)
      })
  },
  async getDoctorStatus({ context, commit }, doctorId) {
    try {
      let response = await this.$axios.get(
        'room/get-doctor-status?doctorId=' + doctorId
      )

      commit('SET_DOCTOR_STATUS', response.data)
      return response.data
    } catch (error) { }
  },
  updateDoctorStatus(context, data) {
    return this.$axios.post('room/update-doctor-status', data)
  },
  updatePrescriptionDialogStatus({
    state, commit, dispatch
  }, data) {
    commit('PRESCRIPTION_ALERT_CLOSED', data)
    try {
      return this.$axios.put("doctors/update-prescription-dialog-status", data)
      //   .then(result => {
      // })
    } catch (error) {
      console.log(error)
    }
  },

  getConsultationHistory({ state }, { patientId, doctorId }) {
    return this.$axios.get(
      `doctors/getConsultationHistory?doctorId=${doctorId}&patientId=${patientId}&date=${state.consultationDate}`
    )
  },
  viewFileBlobUrl({ commit, rootState }, file) {
    const hashids = new Hashids('connect and consult', 10);
    const hashedId = hashids.encode(file.id);
    return this.$axios
      .get('doctors/getPatientFileV1?id=' + hashedId, {
        responseType: 'blob'
      })
  },
  getPatientFileDetails({ commit, rootState }, bookingId) {

    commit('SET_PATIENT_FILES_LOADING', true)
    return this.$axios
      .get('doctors/getPatientFileDetails?bookingId=' + bookingId)
      .then(data => {
        commit('SET_PATIENT_FILES', data.data.files)
        commit('SET_PATIENT_FILES_COUNT', data.data.filesCount ? data.data.filesCount.uploadedFileCount : 0)
        commit('SET_ALLOWED_PATIENT_FILES_COUNT', data.data.filesCount.allowedPatientFileCount)
        commit('SET_PATIENT_FILES_LOADING', false)
      })
  },
  async viewSendNotifications({ commit, rootState }, item) {
    commit('SET_PATIENT_NOTIFICATION_LOADING', true)
    return await this.$axios
      .get('notification/get-firebaseEvent?bookingId=' + item)
      .then(data => {
        if (data.data) {
          const val = data.data.sort((a, b) => (a.id - b.id))
          commit('SET_PATIENT_NOTIFICATION', val)
        }
        commit('SET_PATIENT_NOTIFICATION_LOADING', false)
      })
  },
  async sendPatientNotification({ commit, rootState }, item) {

    return await this.$axios.post('notification/add-firebaseEvent', item)

  },
  async readFirebaseEvent({ commit, rootState }, item) {
    return await this.$axios
      .put('notification//mark-read-firebaseEvent?roomId=' + item)
  },
  fetchPhysicalAppointments({ commit, state, rootState }) {
    if (state.appointmentsSource) {
      state.appointmentsSource.cancel('Next request triggered')
    }
    commit('SET_APPOINTMENTS_SOURCE', this.$axios.CancelToken.source())
    commit('SET_LOADING', true)
    const doctorId = rootState.auth.user.doctorId
    const fromDate = state.consultationDate
    const toDate = state.consultationDate
    const locationId = this.$auth.user.locationId
    let advanceCount = 0;
    let walkinCount = 0;
    let newvisitCount = 0
    let followupCount = 0;
    let revisitCount = 0;
    let advCheckinCount = 0;
    let advVisitedCount = 0;
    let walkinVisitedCount = 0;
    let advanceDiff = 0;
    let advanceDiffCount = 0;
    let newvisitDiff = 0;
    let followupDiff = 0;
    let revisitDiff = 0;
    return this.$axios
      .get(
        'doctors/' +
        doctorId +
        '/physical-appointments?locationId=' +
        locationId +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate,
        {
          cancelToken: state.appointmentsSource.token
        }
      )
      .then(response => {
        let res = response.data.bookings.filter(appointment => appointment.status === 3);
        res.forEach(element => {
          if (element.consultationStartedAt && element.consultationCompletedAt) {
            if (element.tokenType === 1) {
              let checkInTime = Utils.formatWithoutAMPM(element.confirmedAt);
              let calcTime = checkInTime > element.consultationTime ? checkInTime : element.consultationTime;
              let startTime = element.calledAt ? element.calledAt : element.consultationStartedAt;
              let aD = Math.round(moment.duration(moment(Utils.formatWithoutAMPM(startTime), 'HH:mm:ss').diff(moment(calcTime, 'HH:mm:ss'))).asMinutes());
              advanceDiff += aD < 0 ? 0 : aD;
              ++advanceDiffCount
            }
            if (element.visitType === 1) {
              let nD = Math.round(moment.duration(moment(Utils.formatWithoutAMPM(element.consultationCompletedAt), 'HH:mm:ss').diff(moment(Utils.formatWithoutAMPM(element.consultationStartedAt), 'HH:mm:ss'))).asMinutes());
              newvisitDiff += nD < 0 ? 0 : nD;
              ++newvisitCount
            } else if (element.visitType === 2) {
              let fD = Math.round(moment.duration(moment(Utils.formatWithoutAMPM(element.consultationCompletedAt), 'HH:mm:ss').diff(moment(Utils.formatWithoutAMPM(element.consultationStartedAt), 'HH:mm:ss'))).asMinutes());
              followupDiff += fD < 0 ? 0 : fD;
              ++followupCount
            } else if (element.visitType === 3) {
              let rD = Math.round(moment.duration(moment(Utils.formatWithoutAMPM(element.consultationCompletedAt), 'HH:mm:ss').diff(moment(Utils.formatWithoutAMPM(element.consultationStartedAt), 'HH:mm:ss'))).asMinutes());
              revisitDiff += rD < 0 ? 0 : rD;
              ++revisitCount
            }
          }
        });
        let dataToCount = response.data.bookings.filter(appointment => appointment.status !== 4)
        dataToCount.forEach(obj => {
          if (obj.tokenType === 1) {
            obj.status === 2 ? ++advCheckinCount : obj.status === 3 ? ++advVisitedCount : '';
            ++advanceCount
          } else {
            obj.status === 3 ? ++walkinVisitedCount : '';
            ++walkinCount
          }

        });
        let scoreCard = {
          waitingPeriod: {
            appointments: advanceDiff ? Math.round(advanceDiff / advanceDiffCount) : '-',
            walkin: '-'
          },
          consultationTat: {
            newVisit: newvisitDiff ? Math.round(newvisitDiff / newvisitCount) : '-',
            folloUp: followupDiff ? Math.round(followupDiff / followupCount) : '-',
            Revisit: revisitDiff ? Math.round(revisitDiff / revisitCount) : '-'
          },
          Scheduled: {
            appointments: advanceCount,
            checkIn: advCheckinCount,
            consulted: advVisitedCount
          },
          WalkIn: {
            checkIn: walkinCount,
            consulted: walkinVisitedCount
          }
        }
        commit('SET_LOADING', false)
        commit('SET_SCORE_CARD', scoreCard);
        commit('SET_APPOINTMENTS', dataToCount);
        commit('SET_DELAY', response.data.delay);
      })
      .catch(error => {
        commit('SET_LOADING', false)
      })
  },
  startPhysicalConsultation({
    state,
    commit,
    dispatch
  }, appointment) {
    this.$axios.put("doctors/startPhysicalConsultation?bookingId=" + appointment.id).then(data => {
      commit('START_CONSULTATION', data.data)
    })
  },

  finishPhysicalConsultation({
    state,
    commit,
    dispatch
  }, appointment) {
    this.$axios.put("doctors/finishPhysicalConsultation?bookingId=" + appointment.id).then(data => {
      commit('FINISH_CONSULTATION', data.data)
    })
  },
  async getPatientLog({ commit, rootState }, item) {

    commit('SET_PATIENT_LOG', [])

    commit('SET_PATIENT_LOG_LOADING', true)
    return await this.$axios.get(`doctors/patient-log?roomId=${item.roomId}&userType=${item.userType}`).then(data => {
      if (data) {
        commit('SET_PATIENT_LOG', data.data)
        commit('SET_PATIENT_LOG_LOADING', false)
      }
    })
  },
}
