export default {
  getFeedbackOptions({ commit }, data) {
    try {
      this.$axios
        .get(
          'admin/feedback?roomId=' + data.roomId + '&type=' + data.type
        )
        .then(response => {
          commit('SET_FEEDBACK_OPTIONS', response.data.feedback)
          commit('SET_FEEDBACK_IS_SUBMITTED', response.data.isSubmitted)
        })
    } catch ({ error }) {}
  },
  submitFeedback({ commit }, feedback) {
    return this.$axios.post('admin/submit-feedback', feedback)
  }
}
