export default {
  getMessages({ commit }, roomId) {
    this.$axios.get("room/messages?roomId=" + roomId).then(response => {
      commit('SET_MESSAGES', response.data)
    })
  },
  readMessages({ commit }, { roomId, type }) {
    this.$axios.put("room/read-messages?roomId=" + roomId + '&type=' + type)
      .then(response => commit('MARK_AS_READ'))
  },
}