export default {
  SET_FEEDBACK_OPTIONS(state, feedbackOptions) {
    state.feedbackOptions = feedbackOptions
  },
  SET_BOOKING_ID(state, bookingId) {
    state.bookingId = bookingId
  },

  SET_FEEDBACK_DIALOG(state, feedbackDialog) {
    state.feedbackDialog = feedbackDialog
  },

  SET_FEEDBACK_IS_SUBMITTED(state, feedbackIsSubmitted) {
    state.feedbackIsSubmitted = feedbackIsSubmitted
  }
}
