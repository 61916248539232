export const state = () => ({
  departments: [

  ],
  doctors: [],
  checkedInDoctorIds: [],
  selectedDoctors: [],
  tickerMessage: '',
  locationDetail: {}
});
