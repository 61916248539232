export default {
  breakpoint: {},
  icons: {
    iconfont: 'md'
  },
  lang: {},
  rtl: false,
  theme: {
    themes: {
      light: {
        primary: '#006ED9',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        error_1:'#E54848',
        primary_1:'#2753BB',
        green:'#14A083',
        background_color:'#F8FBFF',
        background_color_1:'#DBE0F2',
        darkblue:'#162039',
        background_color_2:"186bcc",
        background_color_3:'#DCE0F0'
      },
    },
  },
}
