<template>
	<v-dialog
		scrollable
		persistent
		width="500"
		v-model="showDialog"
		@click:outside="$emit('user-cancel')"
	>
		<template>
			<v-card class="pt-8">
				<v-card-text>
					<h4>{{ title }}</h4>
					<p class="mb-0">{{ bodyText }}</p>
				</v-card-text>
				<v-card-actions class="justify-end mb-2">
					<v-btn
						v-if="showCloseButton"
						text
						@click="$emit('user-cancel')"
						style="text-transform: none;"
						>{{ secondaryButtonText }}</v-btn
					>
					<v-btn
						class="elevation-0 ml-3 bd-rounded-1"
						:color="confirmationButtonColor"
						style="text-transform: none; color: white;"
						:loading="loading"
						@click="$emit('user-confirmed')"
						x-large
						>{{ confirmationButtonText }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</template>
	</v-dialog>
</template>

<script>
export default {
	props: {
		showDialog: false,
		showCloseButton: {
			required: false,
			default: true,
			type: Boolean
		},
		secondaryButtonText: {
			required: false,
			default: 'Close',
			type: String
		},
		loading: {
			required: false,
			default: false,
			type: Boolean
		},
		source: String,
		title: String,
		bodyText: String,
		confirmationButtonText: String,
		confirmationButtonColor: String
	}
};
</script>

<style></style>
