import Utils from "../../utils/utils";
export default {
  getUsers({
    commit,
    rootState
  }) {
    const locationId = rootState.auth.user.locationId;
    this.$axios.get('admin/users?locationId=' + locationId + '&date=' + Utils.getCurrentDate())
      .then(response => {
        commit('SET_USERS', response.data.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        }))
      }).catch(e => {

      })
  },
  createUser({
    commit,
    dispatch,
    rootState
  }, user) {
    const locationId = rootState.auth.user.locationId;
    user.locationId = locationId;
    this.$axios.post('auth/signup', user)
      .then(response => {
        commit('ADD_USER', response.data);
        dispatch('getUsers')
      }).catch(e => {

      })
  },
  editUser({
    commit
  }, {
    user,
    index
  }) {
    this.$axios.post('admin/users/edit', user)
      .then(response => {
        let editedUser = response.data;
        if (editedUser.doctorId) {
          editedUser.doctorId = parseInt(editedUser.doctorId);
        }
        commit('SET_USER', editedUser)
      }).catch(e => {

      })
  },
  updateBearerToken({
    commit
  }, item) {

    return this.$axios.put('auth/updateToken', item);

  },
  updateChildLocationValues({
    commit,
    dispatch,
    rootState
  }, status) {
    let data = [...this.$auth.user.childLocationData]
    // data.unshift({
    //   departmentName: this.$auth.user.departmentName,
    //   doctorId: this.$auth.user.doctorId,
    //   locationName: this.$auth.user.locationName,
    //   otcLocId: this.$auth.user.otcLocId,
    // })
    commit('SET_CHILD_LOCATIONS', data)
    if (status) {
      commit('SET_SELECTED_LOCATION', data.length > 0 ? data[0] : null)
    } else {
      if (rootState.admin.selectedLocation === null) {
        commit('SET_SELECTED_LOCATION', data.length > 0 ? data[0] : null)
      }
    }

  },
  updateFirebaseToken({ commit }, data) {
    return this.$axios.put('notification/update-doctor-firebaseToken', data);
  }
}
