import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'typeface-lato/index.css';
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'babel-polyfill'

Vue.use(Vuetify, {
  // icons: {
  //   iconfont: 'md'
  // }
})



