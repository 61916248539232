const middleware = {}

middleware['has-id'] = require('../middleware/has-id.js')
middleware['has-id'] = middleware['has-id'].default || middleware['has-id']

middleware['is-admin'] = require('../middleware/is-admin.js')
middleware['is-admin'] = middleware['is-admin'].default || middleware['is-admin']

middleware['is-doctor'] = require('../middleware/is-doctor.js')
middleware['is-doctor'] = middleware['is-doctor'].default || middleware['is-doctor']

export default middleware
