<template>
  <v-app>
    <!-- <v-container class=""> -->
    <v-card
      style="border-right:8px solid red"
      v-if="error.statusCode === 401"
      class="d-flex pa-3 align-center mx-10 my-5"
      elevation="2"
    >
      <v-icon color="error" class="mr-2">error</v-icon>
      You don't have permission to access this page.
      <v-btn class="ml-auto" outlined small color="primary" @click="goHome"
        >Go home</v-btn
      >
    </v-card>
    <v-container v-else-if="error.statusCode === 404" class="fill-height" fluid>
      <v-row align="center" justify="center" class="d-flex flex-column">
        <p class="display-4 font-weight-black">
          404
        </p>
        <p class="display-1 mb-2">
          Oops, Page not found!
        </p>
        <p class="headline grey--text mb-2">The link might be corrupted,</p>
        <p>or the page may have been removed.</p>
        <v-btn color="black white--text" @click="goHome">Go home</v-btn>
      </v-row>
    </v-container>
    <v-container v-else class="fill-height" fluid>
      <v-row align="center" justify="center" class="d-flex flex-column">
        <p class="display-4 font-weight-black">
          {{error.statusCode}}
        </p>
        <p class="display-1 mb-2">
          Oops, Something went wrong!
        </p>
        <p class="headline grey--text mb-2">Currently unable to handle this request.</p>
        <p>Please try again later.</p>
        <v-btn color="black white--text" @click="goHome">Go home</v-btn>
      </v-row>
    </v-container>
    <!-- </v-container> -->
  </v-app>
</template>
<script>
export default {
  layout: 'blank',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  },
  methods: {
    goHome() {
      this.$router.push({
        name:
          this.$auth.user && this.$auth.user.userTypeId === 1
            ? 'appointmentsInfo'
            : 'admin'
      })
    }
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    }
  },
  mounted() {
  }
}
</script>
<style scoped>
h1 {
  font-size: 20px;
}
</style>
