import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10ee8f2c = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _afe6cb0c = () => interopDefault(import('../pages/appointment.vue' /* webpackChunkName: "pages/appointment" */))
const _58eb1828 = () => interopDefault(import('../pages/appointment/notes.vue' /* webpackChunkName: "pages/appointment/notes" */))
const _5d3255e9 = () => interopDefault(import('../pages/appointments.vue' /* webpackChunkName: "pages/appointments" */))
const _500eac55 = () => interopDefault(import('../pages/appointments/call-mask.vue' /* webpackChunkName: "pages/appointments/call-mask" */))
const _9bde0f34 = () => interopDefault(import('../pages/appointments/consultation-history.vue' /* webpackChunkName: "pages/appointments/consultation-history" */))
const _85217e5e = () => interopDefault(import('../pages/appointments/files.vue' /* webpackChunkName: "pages/appointments/files" */))
const _760ba01b = () => interopDefault(import('../pages/appointments/notes.vue' /* webpackChunkName: "pages/appointments/notes" */))
const _3702cefe = () => interopDefault(import('../pages/appointments/notification.vue' /* webpackChunkName: "pages/appointments/notification" */))
const _6990ba1a = () => interopDefault(import('../pages/appointments/prescriptions.vue' /* webpackChunkName: "pages/appointments/prescriptions" */))
const _43c4d917 = () => interopDefault(import('../pages/appointments/upload.vue' /* webpackChunkName: "pages/appointments/upload" */))
const _5ca5a705 = () => interopDefault(import('../pages/appointments/write-prescriptions.vue' /* webpackChunkName: "pages/appointments/write-prescriptions" */))
const _636c0737 = () => interopDefault(import('../pages/appointmentsInfo.vue' /* webpackChunkName: "pages/appointmentsInfo" */))
const _204d9423 = () => interopDefault(import('../pages/appointmentsInfo/call-mask.vue' /* webpackChunkName: "pages/appointmentsInfo/call-mask" */))
const _6c3ec3d8 = () => interopDefault(import('../pages/appointmentsInfo/consultation-history.vue' /* webpackChunkName: "pages/appointmentsInfo/consultation-history" */))
const _7bf380c2 = () => interopDefault(import('../pages/appointmentsInfo/files.vue' /* webpackChunkName: "pages/appointmentsInfo/files" */))
const _7aa29ee9 = () => interopDefault(import('../pages/appointmentsInfo/notes.vue' /* webpackChunkName: "pages/appointmentsInfo/notes" */))
const _a640141a = () => interopDefault(import('../pages/appointmentsInfo/notification.vue' /* webpackChunkName: "pages/appointmentsInfo/notification" */))
const _044865fe = () => interopDefault(import('../pages/appointmentsInfo/patient-info.vue' /* webpackChunkName: "pages/appointmentsInfo/patient-info" */))
const _0f01f3c1 = () => interopDefault(import('../pages/appointmentsInfo/prescriptions.vue' /* webpackChunkName: "pages/appointmentsInfo/prescriptions" */))
const _5be497ee = () => interopDefault(import('../pages/appointmentsInfo/upload.vue' /* webpackChunkName: "pages/appointmentsInfo/upload" */))
const _b690b55a = () => interopDefault(import('../pages/appointmentsInfo/write-prescriptions.vue' /* webpackChunkName: "pages/appointmentsInfo/write-prescriptions" */))
const _01041d3e = () => interopDefault(import('../pages/consultation.vue' /* webpackChunkName: "pages/consultation" */))
const _142958a8 = () => interopDefault(import('../pages/doctor-home.vue' /* webpackChunkName: "pages/doctor-home" */))
const _73ef3460 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _7557dcfd = () => interopDefault(import('../pages/general-guidelines.vue' /* webpackChunkName: "pages/general-guidelines" */))
const _2a33dcd8 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _e07df0f8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _9f5afab8 = () => interopDefault(import('../pages/minimum-requirements.vue' /* webpackChunkName: "pages/minimum-requirements" */))
const _f842ff50 = () => interopDefault(import('../pages/patient-room.vue' /* webpackChunkName: "pages/patient-room" */))
const _2cd23791 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _92df4e64 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _968c53e6 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _0bd0eeb6 = () => interopDefault(import('../pages/signup-configuration.vue' /* webpackChunkName: "pages/signup-configuration" */))
const _5d010525 = () => interopDefault(import('../pages/signup-confirmation.vue' /* webpackChunkName: "pages/signup-confirmation" */))
const _9e87e80e = () => interopDefault(import('../pages/video-compatibility.vue' /* webpackChunkName: "pages/video-compatibility" */))
const _73cd3726 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d5448dd6 = () => interopDefault(import('../pages/display-split/_id.vue' /* webpackChunkName: "pages/display-split/_id" */))
const _28ba9ec8 = () => interopDefault(import('../pages/display/_id.vue' /* webpackChunkName: "pages/display/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _10ee8f2c,
    name: "admin"
  }, {
    path: "/appointment",
    component: _afe6cb0c,
    name: "appointment",
    children: [{
      path: "notes",
      component: _58eb1828,
      name: "appointment-notes"
    }]
  }, {
    path: "/appointments",
    component: _5d3255e9,
    name: "appointments",
    children: [{
      path: "call-mask",
      component: _500eac55,
      name: "appointments-call-mask"
    }, {
      path: "consultation-history",
      component: _9bde0f34,
      name: "appointments-consultation-history"
    }, {
      path: "files",
      component: _85217e5e,
      name: "appointments-files"
    }, {
      path: "notes",
      component: _760ba01b,
      name: "appointments-notes"
    }, {
      path: "notification",
      component: _3702cefe,
      name: "appointments-notification"
    }, {
      path: "prescriptions",
      component: _6990ba1a,
      name: "appointments-prescriptions"
    }, {
      path: "upload",
      component: _43c4d917,
      name: "appointments-upload"
    }, {
      path: "write-prescriptions",
      component: _5ca5a705,
      name: "appointments-write-prescriptions"
    }]
  }, {
    path: "/appointmentsInfo",
    component: _636c0737,
    name: "appointmentsInfo",
    children: [{
      path: "call-mask",
      component: _204d9423,
      name: "appointmentsInfo-call-mask"
    }, {
      path: "consultation-history",
      component: _6c3ec3d8,
      name: "appointmentsInfo-consultation-history"
    }, {
      path: "files",
      component: _7bf380c2,
      name: "appointmentsInfo-files"
    }, {
      path: "notes",
      component: _7aa29ee9,
      name: "appointmentsInfo-notes"
    }, {
      path: "notification",
      component: _a640141a,
      name: "appointmentsInfo-notification"
    }, {
      path: "patient-info",
      component: _044865fe,
      name: "appointmentsInfo-patient-info"
    }, {
      path: "prescriptions",
      component: _0f01f3c1,
      name: "appointmentsInfo-prescriptions"
    }, {
      path: "upload",
      component: _5be497ee,
      name: "appointmentsInfo-upload"
    }, {
      path: "write-prescriptions",
      component: _b690b55a,
      name: "appointmentsInfo-write-prescriptions"
    }]
  }, {
    path: "/consultation",
    component: _01041d3e,
    name: "consultation"
  }, {
    path: "/doctor-home",
    component: _142958a8,
    name: "doctor-home"
  }, {
    path: "/forgot-password",
    component: _73ef3460,
    name: "forgot-password"
  }, {
    path: "/general-guidelines",
    component: _7557dcfd,
    name: "general-guidelines"
  }, {
    path: "/home",
    component: _2a33dcd8,
    name: "home"
  }, {
    path: "/login",
    component: _e07df0f8,
    name: "login"
  }, {
    path: "/minimum-requirements",
    component: _9f5afab8,
    name: "minimum-requirements"
  }, {
    path: "/patient-room",
    component: _f842ff50,
    name: "patient-room"
  }, {
    path: "/redirect",
    component: _2cd23791,
    name: "redirect"
  }, {
    path: "/reset-password",
    component: _92df4e64,
    name: "reset-password"
  }, {
    path: "/signup",
    component: _968c53e6,
    name: "signup"
  }, {
    path: "/signup-configuration",
    component: _0bd0eeb6,
    name: "signup-configuration"
  }, {
    path: "/signup-confirmation",
    component: _5d010525,
    name: "signup-confirmation"
  }, {
    path: "/video-compatibility",
    component: _9e87e80e,
    name: "video-compatibility"
  }, {
    path: "/",
    component: _73cd3726,
    name: "index"
  }, {
    path: "/display-split/:id?",
    component: _d5448dd6,
    name: "display-split-id"
  }, {
    path: "/display/:id?",
    component: _28ba9ec8,
    name: "display-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
