import Utils from "../utils/utils";
export default {
  getDoctors({ commit, rootState }) {
    const locationId = rootState.auth.user.locationId;
    return this.$axios.get('doctors?locationId=' + locationId)
      .then(data => {
        commit('SET_DOCTOR_LIST', data.data);
      }).catch(e => {
      });
  },
  getUsers(context) {
    return this.$axios.get('admin/users?date=' + Utils.getCurrentDate())
  },
  captureSpeed(context, { roomId, networkSpeed }) {
    this.$axios.post('room/capture-speed?roomId=' + roomId + '&networkSpeed=' + networkSpeed)
  },
  generateOTP({commit}, {email, doctorId}){
    return this.$axios.get('admin/forgot-password?email='+email+'&doctorId='+doctorId)
    .then(data => {
      commit('SET_OTP_DETAILS', data.data)
      return data.data
    })
  },
  verifyOTP({state}, otp){
    return this.$axios.post('admin/verify-otp?id=' + state.otpDetails.otpId + '&otp=' + otp)
  },
  resetPassword(context, params){
    return this.$axios.post('admin/reset-passwordV1',params)
  },
  assistedConfiguration(context, params){
    return this.$axios.post('admin/signupAssistedConfiguration',params)
  },
  selfConfiguration(context, params){
    return this.$axios.post('admin/signupSelfConfiguration',params)
  },
  selfSignupDetailsExists(context, params){
    return this.$axios.post('admin/signupDetailsExists',params)
  },
  makeMaskedCall({context,commit}, roomId) {
    commit('SET_CALL_LOADING',true)
   return this.$axios.get('/room/make-masked-call?roomId=' + roomId+'&doctorChannel=browser').then(res=>{
      commit('SET_CALL_LOADING',false)
      return res;
    }).catch(err=>{
      commit('SET_CALL_LOADING',false)
      console.log(err)
      return null
    })
  }
}
