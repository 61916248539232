import Utils from "../../utils/utils";
export default {
  fetchDoctors({
    commit,
    rootState,
    state
  }) {
    const locationId = state.locationDetail.locationId;
    return new Promise((resolve, reject) => {
      this.$axios.get(`admin/getUsers?locationId=${locationId}&date=${Utils.getCurrentDate()}`)
        .then(response => {
          commit('SET_DOCTORS', response.data.sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          }))
          resolve(response);
        }).catch(e => {
          reject(e);
        })
    })
  },
  fetchCheckedInDoctorIds({
    commit,
    state
  }) {
    const locationId = state.locationDetail.locationId;
    return new Promise((resolve, reject) => {
      this.$axios.get(`doctors/checked-in-doctors?date=${Utils.getCurrentDate()}&locationId=${locationId}`)
        .then(data => {
          commit('SET_CHECKED_IN_DOCTOR_IDS', data.data)
          resolve(data);
        }).catch(e => {
          reject(e);
        })
    })
  },
  init({
    commit
  },key) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`admin/init?key=${key}`)
        .then(data => {
          commit('SET_LOCATION_DETAIL', data.data)
          resolve(data);
        }).catch(e => {
          reject(e);
        })
    })
  }
}
