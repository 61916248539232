export default {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_USER(state, payload) {
    const item = state.users.find(user => user.id === payload.id);
    Object.assign(item, payload);
  },
  ADD_USER(state, user) {
    state.users.push(user);
  },
  SET_CHILD_LOCATIONS(state,childLocations){
    state.childLocations=childLocations
  },
  SET_SELECTED_LOCATION(state,selectedLocation){
    state.selectedLocation=selectedLocation
  },
}
