import Utils from "../../utils/utils";
export const state = () => ({
  consultationDate: Utils.getCurrentDate(),
  appointments: [],
  blobUrl: null,
  consultationInfo: null,
  appointmentsSource: null,
  callingAppointment: {},
  loading: false,
  checkInLoading: false,
  reports: [],
  reportsLoading: false,
  patientFilesLoading: false,
  labPatient: null,
  reportDialog: false,
  prescriptionsDialog: false,
  writePrescriptionsDialog: false,
  consultationHistoryDialog: false,
  callMaskDialog: false,
  doctorId: null,
  doctors: [],
  scheduledHeaders: [
    // {
    //   text: '',
    //   value: 'status',
    //   width: '2',
    //   class: 'status-header',
    //   sortable: false,
    //   align: 'start'
    // },
    // { text: '', value: 'status', sortable: false, align: 'center' },
    {
      text: 'Sl.No',
      value: 'no',
      align: 'center',
    },
    {
      text: 'Time',
      value: 'consultationTime',
      sortable: false,
      class: 'body-2'
    },

    {
      text: 'Name',
      value: 'name',
      sortable: false,
      class: 'body-2'
    },
    {
      text: 'Location',
      value: 'area',
      sortable: false,
      class: 'body-2'
    },
    {
      text: 'Age',
      value: 'dateOfBirth',
      sortable: false,
      align: 'center',
      class: 'body-2'
    },
    {
      text: 'Gender',
      value: 'gender',
      sortable: false,
      align: 'center',
      class: 'body-2'
    },
    {
      text: 'UHID/OP No.',
      value: 'uhid',
      sortable: false,
      class: 'body-2'
    }, {
      text: 'Status',
      value: 'isOnline',
      sortable: false,
      align: 'center',
      class: 'body-2'
    },
    { text: 'Actions', value: 'actions', sortable: false, align: 'center', class: 'body-2' },
    { text: 'Feedback', value: 'feedbackSubmitted', sortable: false, class: 'body-2' },
    // { text: '', value: 'status', sortable: false, align: 'left', },
  ],
  walkInHeaders: [{
    text: 'No',
    value: 'no',
    sortable: false,
    align: 'center'
  },
  {
    text: 'Patient',
    value: 'name',
    sortable: false,
    class: 'px-0'
  },
  {
    text: 'Token',
    value: 'token',
    align: 'center',
    sortable: false
  },
  {
    text: 'Waiting time',
    value: 'waitingTime',
    align: 'center',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'end'
  }
  ],
  uploadDialog: false,
  uploadPatient: {},
  uploadLoading: false,
  noteDialog: false,
  notes: [],
  notesPatientId: null,
  filesDialog: false,
  patientFiles: [],
  patientDetails: [],
  doctorStatus: null,
  scoreCard: {
    waitingPeriod: {
      appointments: 0,
      walkin: 0
    },
    consultationTat: {
      newVisit: 0,
      folloUp: 0,
      Revisit: 0
    },
    Scheduled: {
      appointments: 0,
      checkIn: 0,
      consulted: 0
    },
    WalkIn: {
      checkIn: 0,
      consulted: 0
    }
  },
  delayDetails: null,
  covidDialog: false,
  physicalAppointmentScheduledHeaders: [
    {
      text: 'Sl.No',
      value: 'no',
      sortable: false,
    },
    {
      text: 'Patient',
      value: 'name',
      class: 'px-0',
      sortable: false
    },
    {
      text: 'Appointment time',
      value: 'appointmentTime',
      align: 'center',
      sortable: false
    },
    {
      text: 'Waiting time',
      value: 'waitingTime',
      align: 'center',
      sortable: false
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'end'
    }
  ],
  physicalAppointmentWalkInHeaders: [{
    text: 'Sl.No',
    value: 'no',
    sortable: false,
    align: 'center'
  },
  {
    text: 'Patient',
    value: 'name',
    sortable: false,
    class: 'px-0'
  },
  {
    text: 'Token',
    value: 'token',
    align: 'center',
    sortable: false
  },
  {
    text: 'CheckIn time',
    value: 'waitingTime',
    align: 'center',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'end'
  }
  ]
  , patientFilesLoading: false,
  doctorOs: null,
  patientFileCount: 0,
  allowedPatientFileCount: 0,
  notificationDialog: false,
  patientNotificationLoading: false,
  patientNotification: [],
  patientStatus: true,
  patientLogs:[],
  patientLogLoading:false
})
