import Vue from 'vue'
import moment from 'moment'

const dateFilter = (value) => {
  if (value) {
    return moment(value.trim(), 'YYYY-MM-DD').format('ddd, MMM DD')
  } else {
    return '';
  }
};
const dateFilter1 = (value) => {
  if (value) {
    return moment(value.trim(), 'YYYY-MM-DD').format('DD-MM-YYYY')
  } else {
    return '';
  }
};
const dateFilter2 = (value) => {
  if (value) {
    return moment(value.trim(), 'YYYY-MM-DD').format('MMM DD, YYYY')
  } else {
    return '';
  }
};
const dateFilter3 = (value) => {
  if (value) {
    return moment(value.trim(), 'YYYY-MM-DD').format("DD-MMM'YY")
  } else {
    return '';
  }
};
const timeFilter = (value) => {
  if (value) {
    return moment(value.trim(), 'HH:mm:ss').format('hh:mm a')
  } else {
    return '';
  }
};

const timeFilter1 = (value) => {
  if (value) {
    return moment(value.trim(), 'HH:mm').format('hh:mm A')
  } else {
    return '';
  }
};

const secondToMinutes = (value) => {
  if (value) {
    // return value >= 60 ? Math.floor(value / 60) + ' hours '+ Math.floor(value % 60)+' minutes'   : value + ' minutes';
    return value + ' minutes'
  } else {
    return '';
  }
};

const birthDateToAge = (dateString) => {
  if (!dateString) return "Age not available";
  let d = dateString.split("-");
  if (d && d.length) {
    return showAge(parseInt(d[0]), parseInt(d[1]), parseInt(d[2]));
  } else {
    return "NA";
  }
};

const showAge = (dobYear, dobMonth, dobDay) => {
  let bthDate, curDate, days;
  let ageYears, ageMonths, ageDays;
  bthDate = new Date(dobYear, dobMonth - 1, dobDay);
  curDate = new Date();
  if (bthDate > curDate) return;
  days = Math.floor((curDate - bthDate) / (1000 * 60 * 60 * 24));
  ageYears = Math.floor(days / 365);
  ageMonths = Math.floor((days % 365) / 31);
  ageDays = days - ageYears * 365 - ageMonths * 31;
  if (!ageYears && ageYears !== 0) return "Age not available";
  if (ageYears > 0) {
    return ageYears === 1 ? ageYears + " Yr" : ageYears + ' Yrs';
  }
  if (!ageMonths && ageMonths !== 0) return "Age not available";
  if (ageMonths > 0) {
    return ageMonths + " Months";
  }
  if (!ageDays && ageDays !== 0) return "Age not available";
  if (ageDays > 0) {
    return ageDays + " Days";
  }
}

const timeStampFilter = (value) => {
  if (value) {
    return ' at ' + moment(value.trim(), 'YYYY-MM-DD HH:mm').format('hh:mm a') + ' on ' + moment(value.trim(), 'YYYY-MM-DD HH:mm').format('ddd, MMM DD')
  } else {
    return '';
  }
};
const timeStampFilter1 = (value) => {
  if (value) {
    return moment(value.trim(), 'YYYY-MM-DD HH:mm').format('MMM DD, YYYY hh:mm a.')
  } else {
    return '';
  }
};
const timeStampFilter2 = (value) => {
  if (value) {
    return moment(value.trim(), 'YYYY-MM-DD HH:mm').format('hh:mm a')
  } else {
    return '';
  }
};

Vue.filter('date', dateFilter);
Vue.filter('time', timeFilter);
Vue.filter('time1', timeFilter1);
Vue.filter('date1', dateFilter1);
Vue.filter('date2', dateFilter2);
Vue.filter('date3', dateFilter3)
Vue.filter('birthDateToAge', birthDateToAge);
Vue.filter('timestamp', timeStampFilter);
Vue.filter('timestamp1', timeStampFilter1);
Vue.filter('timestamp2', timeStampFilter2);
Vue.filter('secondToMinutes', secondToMinutes);

